import { render, staticRenderFns } from "./shipCompany.vue?vue&type=template&id=1a0f2928&scoped=true"
import script from "./shipCompany.vue?vue&type=script&lang=js"
export * from "./shipCompany.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a0f2928",
  null
  
)

export default component.exports